<mat-form-field
  appearance="outline"
  class="pick-lookup"
  (click)="open()"
  [ngClass]="{
    'mat-form-field-invalid':
      showError &&
      ((!multiple && !selectedItem) ||
        (multiple && (selectedItems?.length || 0) === 0))
  }"
>
  <mat-label>{{ label }}</mat-label>
  <mat-icon
    *ngIf="!isLoading && showSearchIcon"
    matPrefix
    [ngStyle]="{ opacity: disabled ? 0.5 : 1 }"
    class="search-icon"
    >search</mat-icon
  >
  <mat-spinner
    *ngIf="isLoading"
    matPrefix
    [diameter]="24"
    class="prefix-spinner"
  ></mat-spinner>
  <mat-select
    matInput
    [formControl]="multiple ? formControlMultiple : formControl"
    (selectionChange)="itemSelected()"
    (openedChange)="focusFilter(filterInput, $event)"
    panelClass="pick-lookup"
    disableOptionCentering="true"
    [required]="required"
    [multiple]="multiple"
    (keydown.enter)="
      firstLoadFinished && totalResults > 0 ? onOpenTry($event) : undefined
    "
    (keyup.enter)="
      firstLoadFinished && totalResults > 0 ? onOpenTry($event) : undefined
    "
    (keydown.arrow-down)="onOpenTry($event)"
    (keyup.arrow-down)="onOpenTry($event)"
    (keydown.arrow-up)="onOpenTry($event)"
    (keyup.arrow-up)="onOpenTry($event)"
    [panelWidth]="null"
  >
    <div class="lookup-filter-wrapper">
      <input
        type="text"
        placeholder="Search..."
        matInput
        class="lookup-filter"
        autocomplete="off"
        #filterInput
        [formControl]="filterControl"
        (keydown.space)="$event.stopPropagation()"
        (keydown.enter)="$event.stopPropagation()"
      />
      <mat-icon
        *ngIf="(filterControl.value?.length || 0) > 0"
        matSuffix
        (click)="clearSearch($event)"
        >close</mat-icon
      >
    </div>
    <div class="lookup-scroll-wrapper" fxLayout="column">
      <ng-content></ng-content>
      <button
        *ngIf="showSelectAll && multiple && (totalResults || 0) > 0"
        mat-button
        class="select-all btn-block"
        (click)="toggleAll()"
      >
        {{
          formControlMultiple.value?.length !== totalResults
            ? 'Select All'
            : 'Deselect All'
        }}
      </button>
      <ng-template #Group let-group="group" let-index="index">
        <div *ngIf="group.children?.length > 0" class="level-{{ index }}">
          {{ group.item?.name || 'UNASSIGNED' }}
        </div>
        <mat-option
          class="level-{{ index }}"
          *ngIf="group.children?.length === 0"
          [value]="group.item"
          [disabled]="disableOption && disableOption(group.item)"
        >
          {{
            !group.children && formatLabel
              ? formatLabel(group.item)
              : group.item.name
          }}
          {{ disableOption && disableOption(group.item) ? disabledLabel : '' }}
        </mat-option>
        <ng-container
          *ngFor="let subGroup of group.children"
          [ngTemplateOutlet]="Group"
          [ngTemplateOutletContext]="{ group: subGroup, index: index + 1 }"
        ></ng-container>
      </ng-template>
      <ng-container
        *ngFor="let group of grouped"
        [ngTemplateOutlet]="Group"
        [ngTemplateOutletContext]="{ group: group, index: 0 }"
      ></ng-container>
      <ng-container *ngIf="grouped.length === 0">
        <mat-option
          *ngFor="let option of results; let i = index"
          [value]="option"
          [disabled]="disableOption && disableOption(option)"
          [ngClass]="{ focused: focusedIndex === i }"
          [attr.data-index]="i"
        >
          {{ formatLabel ? formatLabel(option) : option.name }}
          {{ disableOption && disableOption(option) ? disabledLabel : '' }}
        </mat-option>
      </ng-container>
      <mat-option
        *ngIf="!!selectedItem && !isSelectedIncluded"
        [value]="selectedItem"
        class="hidden"
      >
        {{ selectedItem.name }}
      </mat-option>

      <div
        *ngIf="totalResults === 0 && !isLoading"
        class="no-matches"
        fxLayoutAlign="center center"
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <p>No Matches</p>
      </div>
      <div
        fxLayoutAlign="center center"
        class="spinner-wrapper"
        *ngIf="isLoading"
      >
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <div
        fxLayoutAlign="center center"
        class="load-more-wrapper"
        *ngIf="!isLoading && (totalResults || 0) > (results?.length || 0)"
      >
        <button
          class="load-more-button"
          mat-raised-button
          (click)="loadMore($event)"
          (keydown.enter)="onOpenTry($event)"
          (keyup.enter)="loadMore($event)"
        >
          Load More
        </button>
      </div>
      <div
        fxLayoutAlign="center center"
        class="load-more-wrapper"
        *ngIf="
          onAddNew &&
          !isLoading &&
          (totalResults || 0) === (results?.length || 0)
        "
      >
        <button
          fxFlex
          class="add-new-button"
          *ngIf="onAddNew"
          mat-raised-button
          (click)="addNew()"
          (keydown.enter)="onOpenTry($event)"
          (keyup.enter)="addNew()"
        >
          {{ addNewLabel }}
        </button>
      </div>
    </div>
  </mat-select>
  <mat-icon
    *ngIf="
      (!required && formControl.value) ||
      (formControlMultiple.value?.length || 0) > 0
    "
    matSuffix
    (click)="clear($event)"
    >close</mat-icon
  >
</mat-form-field>
